<template>
  <div class="compare-component">
    <div class="d-none d-md-inline-block">
      <ul class="list-group list-group-horizontal my-4">
        <li v-for="tab in config.tabs" :key="tab" class="list-group-item">
          <a href="#" @click="loadProducts(tab)">{{ tab.label }}</a>
        </li>
      </ul>
    </div>
    <div v-if="is_loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <ComparisonTable :tableHeaders="tableHeaders" :products="products" />
  </div>
</template>

<script>
import ComparisonTable from "./ComparisonTable.vue";

export default {
  components: {
    ComparisonTable,
  },
  data() {
    return {
      config: [],
      tableHeaders: [
        { label: "Image", key: "image", width: "10%" },
        { label: "Name", key: "name", width: "30%" },
        { label: "Description", key: "description", width: "40%" },
        { label: "Cost", key: "price", width: "10%" },
        { label: "", key: "url", width: "10%" },
      ],
      products: [
        {
          image: this.defaultImage,
          name: "product #1",
          description: "product #1",
          price: "£0.00",
          url: "/",
        },
        {
          image: this.defaultImage,
          name: "product #1",
          description: "product #1",
          price: "£0.00",
          url: "/",
        },
        {
          image: this.defaultImage,
          name: "product #1",
          description: "product #1",
          price: "£0.00",
          url: "/",
        },
        {
          image: this.defaultImage,
          name: "product #1",
          description: "product #1",
          price: "£0.00",
          url: "/",
        },
        {
          image: this.defaultImage,
          name: "product #1",
          description: "product #1",
          price: "£0.00",
          url: "/",
        },
      ],
      activeTab: "",
      is_loading: false,
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    getConfig() {
      this.is_loading = true;
      this.axios
        .get(this.domain + "/api/config")
        .then((response) => {
          this.config = response.data.data;
        })
        .finally(() => {
          this.is_loading = false;
          this.loadProducts(this.config.tabs[0]);
        });
    },
    getPopular(key) {
      this.is_loading = true;
      this.$emit("picked", key);
      this.axios
        .get(this.domain + "/api/popular?tags=" + key)
        //.then(sleeper(2000))
        .then((response) => {
          this.tableHeaders = response.data.data.tableHeaders;
          this.products = response.data.data.products;
          this.is_loading = false;
        });
    },
    loadProducts(tab) {
      this.getPopular(tab.key);
    },
  },
};
</script>

<style>
</style>