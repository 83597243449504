<template>
  <nav
    class="navbar navbar-expand-lg navbar-dark bg-dark"
    aria-label="Tenth navbar example"
  >
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample08"
        aria-controls="navbarsExample08"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse justify-content-md-center"
        id="navbarsExample08"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link text-white"
              :href="
                $router.resolve({
                  name: 'Home',
                }).href
              "
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              :href="
                $router.resolve({
                  name: 'ProductCompareAll',
                }).href
              "
              title="See our product comparisons"
              >Compare products</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              :href="
                $router.resolve({
                  name: 'ProductArticlesAll',
                }).href
              "
              title="Read our articles"
              >Read articles</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              :href="
                $router.resolve({
                  name: 'About',
                }).href
              "
              title="Read more about this website."
              >About</a
            >
          </li>
          <li>
            <div v-if="config.meta.socialmedia" class="pt-2">
              <a
                class="nav-link d-inline text-white"
                :href="
                  'https://facebook.com/' + config.meta.socialmedia.facebook
                "
                target="_blank"
                rel="nofollow"
                title="Follow us on Facebook"
                v-if="config.meta.socialmedia.facebook"
              >
                <i class="fab fa-facebook"></i>
              </a>
              <a
                class="nav-link d-inline text-white"
                :href="
                  'https://twitter.com/' + config.meta.socialmedia.twitter
                "
                target="_blank"
                rel="nofollow"
                title="Follow us on Twitter"
                v-if="config.meta.socialmedia.twitter"
              >
                <i class="fab fa-twitter"></i>
              </a>
              <a
                class="nav-link d-inline text-white"
                :href="
                  'https://facebook.com/' + config.meta.socialmedia.instagram
                "
                target="_blank"
                rel="nofollow"
                title="Follow us on Instagram"
                v-if="config.meta.socialmedia.instagram"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container d-none d-md-block">
    <div class="nav-scroller py-1 mb-2">
      <nav class="nav d-flex justify-content-between">
        <a
          v-for="tab in config.tabs"
          :key="tab"
          :href="
            $router.resolve({
              name: 'ProductCategory',
              params: { id: tab.slug },
            }).href
          "
          :title="'Browse and compare all our ' + tab.label + ' products'"
          class="p-2 text-muted"
          >{{ tab.label }}</a
        >
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["config"],
};
</script>

<style>
</style>