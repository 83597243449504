<template>
  <component  v-html="jsonld" type="application/ld+json" :is="'script'"></component>
</template>
<script>
export default {
  props: ["schema"],
  data() {
    return {
      jsonld: null,
    };
  },
  mounted() {
    this.jsonld = this.schema;
  }
};
</script>
<style>
</style>