<template>
  <!-- <div class="row ">
    <div class="col-sm-12">
      <div class="container"> -->
  <footer class="container-fuid py-5 bg-dark">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-4 mb-3">
          <h5 class="text-white border-bottom pb-2">Compare</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2" v-for="tab in tabs" :key="tab">
              <a
                :href="
                  $router.resolve({
                    name: 'ProductCompareTopForCurrentYear',
                    params: { id: tab.slug, year: year, number: 10 },
                  }).href
                "
                class="nav-link p-0 text-white"
                :title="'Compare Top 10 ' + tab.label + ' for ' + year"
                >Compare Top 10 {{ tab.label }} for {{ year }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                :href="
                  $router.resolve({
                    name: 'ProductCompareAll',
                  }).href
                "
                title="Compare all products"
                class="nav-link p-0 text-white"
              >
                Compare all products</a
              >
            </li>
          </ul>

          <h5 class="text-white border-bottom pb-2 mt-3">Compare archive</h5>

          <ul class="nav flex-column">
            <li class="nav-item mb-2">
              <a
                :href="
                  $router.resolve({
                    name: 'ProductCompareArchiveAll',
                  }).href
                "
                title="Compare all products"
                class="nav-link p-0 text-white"
              >
                Browse previous years top list</a
              >
            </li>
          </ul>
        </div>

        <div class="col-xs-12 col-md-4 mb-3">
          <h5 class="text-white border-bottom pb-2">Articles</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2" v-for="tab in tabs" :key="tab">
              <a
                :href="
                  $router.resolve({
                    name: 'ProductArticles',
                    params: { id: tab.slug },
                  }).href
                "
                class="nav-link p-0 text-white"
                :title="'Read our latest ' + tab.label_singular + ' Articles'"
                >{{ tab.label_singular }} Articles</a
              >
            </li>
          </ul>
        </div>

        <div class="col-xs-12 col-md-4 mb-3">
          <h5 class="text-white border-bottom pb-2">Browse all</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2" v-for="tab in tabs" :key="tab">
              <a
                :href="
                  $router.resolve({
                    name: 'ProductCategory',
                    params: { id: tab.slug },
                  }).href
                "
                class="nav-link p-0 text-white"
                :title="'Browse all ' + tab.label_singular + ' products'"
                >Browse all {{ tab.label_singular }} products</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="d-block border-top mt-4 footer-notice text-white">
        <div class="d-block mt-5">© Copyright Affiligator</div>

        <div class="d-block my-4">
          <p>
            Product prices and availability are accurate as of the date/time
            indicated and are subject to change. Any price and availability
            information displayed on amazon.co.uk at the time of purchase will
            apply to the purchase of this product.
          </p>

          <p>As an Amazon Associate we earn from qualifying purchases.</p>

          <p>
            The information contained in this website is for general information
            purposes only. The information is provided by Affiligator and while
            we endeavour to keep the information up to date and correct, we make
            no representations or warranties of any kind, express or implied,
            about the completeness, accuracy, reliability, suitability or
            availability with respect to the website or the information,
            products, services, or related graphics contained on the website for
            any purpose. Any reliance you place on such information is therefore
            strictly at your own risk.
          </p>
          <p>
            In no event will we be liable for any loss or damage including
            without limitation, indirect or consequential loss or damage, or any
            loss or damage whatsoever arising from loss of data or profits
            arising out of, or in connection with, the use of this website.
          </p>

          <p>
            Through this website you are able to link to other websites which
            are not under the control of Affiligator. We have no control over
            the nature, content and availability of those sites. The inclusion
            of any links does not necessarily imply a recommendation or endorse
            the views expressed within them.
          </p>

          <p>
            Every effort is made to keep the website up and running smoothly.
            However, Affiligator takes no responsibility for, and will not be
            liable for, the website being temporarily unavailable due to
            technical issues beyond our control.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from "moment";
export default {
  props: ["config"],
  data() {
    return {
      tabs: [],
      year: "",
    };
  },
  mounted() {
    let templateObj = {
      label: "Holding",
      slug: "default",
    };
    for (let i = 0; i <= 10; i++) {
      this.tabs.push(templateObj);
    }
    this.year = moment().format("YYYY");
  },
  watch: {
    config: function () {
      this.tabs = this.config.tabs;
    },
  },
};
</script>

<style>
.footer-notice {
  font-size: 11px;
}
</style>