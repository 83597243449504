<template>
  <Meta :meta="subject.meta" />
  <Schema v-for="schema in subject.schemas" :key="schema" :schema="schema" />
  <div class="row">
    <div class="col-sm-12">
      <div class="container">
        <main role="main" class="my-3">
          <div class="row">
            <div class="col-md-8 blog-main">
              <div class="blog-post border-top pt-3">
                <h1 class="blog-post-title">{{ subject.label }} Articles</h1>
                <h2>Why {{ subject.label }} Are Awesome</h2>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div v-for="article in articles" :key="article" class="col-md-6">
              <div class="card flex-md-row mb-4 box-shadow h-md-250">
                <div class="card-body d-flex flex-column align-items-start">
                  <strong class="d-inline-block mb-2 text-primary">{{
                    article.tag
                  }}</strong>
                  <h3 class="mb-0">
                    <router-link
                      :to="{
                        name: 'Article',
                        params: { id: article.slug },
                      }"
                      class="text-dark"
                      @click="reloadArticle(article.slug)"
                      >{{ article.title }}</router-link
                    >
                  </h3>
                  <div class="mb-1 text-muted">{{ article.displayDate }}</div>
                  <p class="card-text mb-auto">{{ article.intro }}</p>
                  <router-link
                    :to="{ name: 'Article', params: { id: article.slug } }"
                    @click="reloadArticle(article.slug)"
                    >Continue reading</router-link
                  >
                </div>
                <img
                  class="
                    card-img-right
                    flex-auto
                    d-none d-md-block
                    recommended-article-image
                  "
                  :src="article.image"
                  :alt="article.title"
                  :title="article.title"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Meta from "./../components/Meta.vue";
export default {
  components: {
    Meta,
  },
  data() {
    return {
      is_loading: false,
      articles: [],
      articlesLoaded: false,
      subject: [],
    };
  },
  mounted() {
    this.getArticles();
  },
  methods: {
    getArticles() {
      this.is_loading = true;
      this.axios
        .get(
          this.domain +
            "/api/articles?key=" +
            this.$route.params.id +
            "&page=articles&all=yes"
        )
        .then((response) => {
          this.articles = response.data.data.articles;
          this.subject = response.data.data.subject;
          this.articlesLoaded = true;
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
</style>