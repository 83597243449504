<template>
  <Meta :meta="article.meta" />
  <Schema v-for="schema in article.schemas" :key="schema" :schema="schema" />
  <div class="row">
    <div class="col-sm-12">
      <div class="container">
        <main role="main" class="my-3">
          <div class="row">
            <div class="col-md-8 blog-main">
              <div class="blog-post border-top pt-3">
                <h1 class="blog-post-title">{{ article.title }}</h1>
                <h2 class="blog-post-title">
                  <mark>{{ article.subject.label }}</mark>
                </h2>
                <p class="blog-post-meta my-4">
                  {{ article.displayDate }} | Reading time:
                  <strong>5 minutes</strong>
                </p>
                <article>
                  <div class="d-block text-center mb-4">
                    <img
                      class="img float-left"
                      :alt="'Cover image for ' + article.image"
                      :src="article.image"
                    />
                  </div>
                  <div
                    v-for="(paragraph, index) in article.content_linked"
                    :key="paragraph"
                    class="my-3"
                  >
                    <component v-html="paragraph" :is="'p'"></component>
                    <div v-if="index == '1'" class="my-4">
                      <h3>Compare Top {{ article.subject.label }}</h3>
                      <ComparisonTable
                        :products="products"
                        :tableHeaders="tableHeaders"
                      />
                      <a href="">Compare more {{ article.subject.label }}</a>
                    </div>
                  </div>
                </article>
              </div>
            </div>

            <aside class="col-md-4 blog-sidebar">
              <div class="p-3 mb-3 bg-light rounded">
                <h4 class="font-italic">About</h4>
                <p class="mb-0">
                  Etiam porta <em>sem malesuada magna</em> mollis euismod. Cras
                  mattis consectetur purus sit amet fermentum. Aenean lacinia
                  bibendum nulla sed consectetur.
                </p>
              </div>

              <div class="p-3">
                <h4 class="font-italic">Archives</h4>
                <ol class="list-unstyled mb-0">
                  <li><a href="#">March 2014</a></li>
                  <li><a href="#">February 2014</a></li>
                  <li><a href="#">January 2014</a></li>
                  <li><a href="#">December 2013</a></li>
                  <li><a href="#">November 2013</a></li>
                  <li><a href="#">October 2013</a></li>
                  <li><a href="#">September 2013</a></li>
                  <li><a href="#">August 2013</a></li>
                  <li><a href="#">July 2013</a></li>
                  <li><a href="#">June 2013</a></li>
                  <li><a href="#">May 2013</a></li>
                  <li><a href="#">April 2013</a></li>
                </ol>
              </div>

              <div class="p-3">
                <h4 class="font-italic">Elsewhere</h4>
                <ol class="list-unstyled">
                  <li><a href="#">GitHub</a></li>
                  <li><a href="#">Twitter</a></li>
                  <li><a href="#">Facebook</a></li>
                </ol>
              </div>
            </aside>
          </div>
        </main>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <Recommended
        :baseSubject="product"
        :currentArticle="article.slug"
        @changeArticle="changeArticle"
      />
    </div>
  </div>
</template>

<script>
import Schema from "./../components/Schema.vue";
import Meta from "./../components/Meta.vue";
import ComparisonTable from "./../components/Products/ComparisonTable.vue";

export default {
  props: ["id"],
  components: {
    ComparisonTable,
    Schema,
    Meta,
  },
  data() {
    return {
      product: "",
      products: [],
      tableHeaders: [],
      article: {
        tite: "",
        html: "",
        dateDisplay: "",
        subject: {
          label: "",
        },
      },
    };
  },
  mounted() {
    this.getArticle();
  },
  methods: {
    changeArticle(slug) {
      this._getArticle(slug);
    },
    getArticle() {
      this._getArticle(this.$route.params.id);
    },
    _getArticle(articleSlug) {
      this.axios
        .get(this.domain + "/api/article", { params: { slug: articleSlug } })
        .then((response) => {
          this.article = response.data.data.article;
          this.getPopular();
        });
    },
    getPopular() {
      this.is_loading = true;
      this.axios
        .get(this.domain + "/api/popular?tags=" + this.article.subject.label)
        .then((response) => {
          this.tableHeaders = response.data.data.tableHeaders;
          this.products = response.data.data.products;
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
article p {
  font-size: 20px;
}
</style>