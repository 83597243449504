<template>
  <div class="compare-table">
    <div v-for="product in products" :key="product">
      <Schema
        v-for="schemaItem in product.schemas"
        :key="schemaItem"
        :schema="schemaItem"
      />
    </div>
    <div v-if="is_loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <table class="table table-bordered table-responsive" v-if="!is_loading">
      <thead>
        <th class="compare-image text-center">Image</th>
        <th class="compare-name text-center">Name</th>
        <th class="compare-description d-none d-sm-none d-md-table-cell text-center">Description</th>
        <th class="compare-price d-none d-sm-none d-md-table-cell highlight text-center">Price</th>
        <th class="compare-action">&nbsp;</th>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product">
          <td class="text-center">
            <a
              :href="product.url"
              target="_blank"
              class=""
              :title="'Click to buy ' + product.name"
              rel="nofollow sponsored"
            >
              <div v-lazy-container="{ selector: 'img' }">
                <img
                  :data-src="product.image"
                  :alt="product.name"
                  :title="product.name + ' for ' + product.price"
                  class="compare-img"
                  :width="width"
                  :height="height"
                />
              </div>
            </a>
          </td>
          <td>
            {{ product.name }}
          </td>
          <td class="d-none d-sm-none d-md-table-cell">
            <span class="d-none d-sm-none d-md-inline">{{
              product.intro
            }}</span>
          </td>
          <td class="highlight text-center d-none d-sm-none d-md-table-cell weighted">
            {{ product.price }}
          </td>
          <td class="text-center">
            <span class="d-sm-none weighted">
              {{ product.price }}
            </span>
            <a
              :href="product.url"
              target="_blank"
              class="btn btn-buy btn-lg"
              :title="'Click to buy ' + product.name"
              rel="nofollow sponsored"
              >Buy <i class="fa fa-extnernal"></i
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Schema from "./../Schema.vue";
export default {
  components: {
    Schema,
  },
  props: ["tableHeaders", "products"],
  data() {
    return {
      width: 100,
      height: 100,
      is_loading: true,
    };
  },
  methods: {
    isBoldDisplayLabel(key) {
      return ["price", "length", "circumference"].includes(key);
    },
    isDisplayLabel(key) {
      return ["intro"].includes(key);
    },
  },
  mounted() {
    this.is_loading = false;
  },
};
</script>

<style>
.compare-image {
  width: 10%;
}
.compare-name {
  width: 30%;
}
.compare-description {
  width: 40%;
}
.compare-price {
  width: 10%;
}
.compare-action {
  width: 10%;
}
</style>