<template>
  <Meta :meta="page.meta" />
  <div class="row">
    <div class="col-sm-12">
      <div class="container">
        <Compare @picked="pickedProduct" />
      </div>
    </div>
  </div>
  <Recommended />
</template>

<script>
import Recommended from "./../components/Articles/Recommend.vue";
import Compare from "./../components/Products/Compare.vue";
import Meta from "./../components/Meta.vue";
export default {
  name: "Home",
  components: {
    Recommended,
    Compare,
    Meta,
  },
  data() {
    return {
      product: null,
      page: {},
    };
  },
  mounted() {
    this.getPage();
  },
  methods: {
    pickedProduct(product) {
      this.product = product;
    },
    getPage() {
      this.axios.get(this.domain + "/api/page?slug=home").then((response) => {
        this.page.meta = response.data.data.meta;
      });
    },
  },
};
</script>
