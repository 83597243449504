<template>
  <div class="row">
    <div class="col-12">
      <div class="container">
          <div class="row">
              <div class="col-12">
                  </div>
              </div>
        <div class="row" v-for="year in years" :key="year">
          <div class="col-12">
            <h1>Top Picks in {{ year }}</h1>
          </div>
          <div
            class="col-xs-12 col-sm-6 col-md-3"
            v-for="subject in subjects"
            :key="subject"
          >
            <div class="card my-3">
              <img
                class="card-img-top"
                :src="subject.cover_image"
                :alt="subject.label + ' cover image'"
              />
              <div class="card-body">
                <h5 class="card-title">
                  Top {{ this.compareDefault }} {{ subject.label }} in
                  {{ year }}
                </h5>
                <p class="card-text">
                  Read our Top {{ this.compareDefault }} picks of
                  {{ subject.label }} in {{ year }}
                </p>
                <a
                  :href="
                    $router.resolve({
                      name: 'ProductCompareTopForCurrentYear',
                      params: {
                        id: subject.slug,
                        year: year,
                        number: this.compareDefault,
                      },
                    }).href
                  "
                  class="btn btn-primary"
                  >Browse Top 10</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      years: [],
      is_loading: true,
      subjects: [],
    };
  },
  mounted() {
    this.years = this.activeYears;
    this.getSubjects();
  },
  methods: {
    getSubjects() {
      this.is_loading = true;
      this.axios
        .get(this.domain + "/api/all-subjects")
        .then((response) => {
          this.subjects = response.data.data.subjects;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
</style>