<template>
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Our Latest Articles</h1>
          </div>
          <div
            class="col-xs-12 col-sm-6 col-md-3"
            v-for="subject in subjects"
            :key="subject"
          >
            <div class="card my-3">
              <img
                class="card-img-top"
                :src="subject.cover_image"
                :alt="subject.label + ' cover image'"
              />
              <div class="card-body">
                <h5 class="card-title">{{ subject.label }}</h5>
                <p class="card-text">
                  Read our reviews, comparisons and top tips articles on
                  {{ subject.label }}.
                </p>
                <a
                  :href="
                    $router.resolve({
                      name: 'ProductArticles',
                      params: { id: subject.slug },
                    }).href
                  "
                  :title="
                    'Read our reviews, comparisons and top tips articles on ' +
                    subject.label
                  "
                  class="btn btn-primary"
                  >Read Latest Articles</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_loading: true,
      subjects: [],
    };
  },
  mounted() {
    this.getSubjects();
  },
  methods: {
    getSubjects() {
      this.is_loading = true;
      this.axios
        .get(this.domain + "/api/all-subjects")
        .then((response) => {
          this.subjects = response.data.data.subjects;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
</style>