<template>
  <Schema v-for="schema in config.meta.schemas" :key="schema" :schema="schema" />
  <Navbar :config="config" />
  <div class="container-fluid">
    <router-view />
  </div>
  <Footer :config="config" />
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import Schema from "./components/Schema.vue";
import "./components/gtm.js";

require("@/assets/global.css");
require("@/assets/" + process.env.VUE_APP_APP + "/global.css");

export default {
  data() {
    return {
      is_loading: true,
      config: { tabs: [], meta: { socialmedia: {}, schemas: [] } },
    };
  },
  components: {
    Navbar,
    Footer,
    Schema,
  },
  mounted() {
    let templateObj = {
      slug: "/",
      label: "Nav",
    };
    for (let i = 0; i <= 10; i++) {
      this.config.tabs.push(templateObj);
    }
    this.getConfig();
  },
  methods: {
    getConfig() {
      this.is_loading = true;
      this.axios
        .get(this.domain + "/api/global-config")
        .then((response) => {
          this.config = response.data.data;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>
