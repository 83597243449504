<template>
  <Meta :meta="config.meta" />
  <div class="row">
    <div class="col-sm-12">
      <div class="container">
        <main role="main" class="my-3">
          <div class="row">
            <div class="col-12">
              <h1>About {{ config.meta.sitename }}</h1>
              <p>
                At {{ config.meta.sitename }}, our mission is to simplify the process
                of finding and comparing {{ config.meta.productName }} online. To
                achieve this, we follow a three-step process:
              </p>

              <h2>
                How we decide which {{ config.meta.productName }} to include on {{ config.meta.sitename }}
              </h2>
              <p>
                For a {{ config.meta.productName }} to be eligible to be listed on our website, they must
                pass tests designed to eliminate sites that may not be reliable or trustworthy.
              </p>

              <ol>
                <li>
                  We only feature {{ config.meta.productName }} from retailers with reviews from trusted Review websites.
                </li>
                <li>
                  The retailer offer customer satisfaction or guarantee in some description.
                </li>
                <li>
                  When purchased online, the retailer is trading as a Ltd company.
                </li>
              </ol>
              <p>
                In addition, we routinely check that all {{ config.meta.productName }} already listed meet these standards and remove them if not.
              </p>

              <p>
                The {{ config.meta.productName }} that meet these criteria are then ordered by an
                algorithm that takes into account a range of factors designed to
                highlight the {{ config.meta.productName }} that are likely well-suited. Finally, our
                team manually review these positions regularly.
              </p>

              <h3>How do we make money?</h3>
              <p>
                We have affiliate agreements with the majority of the retailers
                that are listed on our website. This means that we are paid a
                commission by these retailers if we are able to help our audience
                find a suitable broker for them.
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Meta from "./../components/Meta.vue";
export default {
  components: {
    Meta,
  },
  data() {
    return {
      config: {
        meta: {},
      },
      meta: {},
      is_loading: true,
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    getConfig() {
      this.is_loading = true;
      this.axios
        .get(this.domain + "/api/config")
        .then((response) => {
          this.config = response.data.data;
          this.config.meta.title = "About " + this.config.meta.title;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
</style>