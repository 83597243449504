<template></template>

<script>
export default {
  mounted() {
    if (window.location.pathname !== "/404") {
      window.location.href = "/404";
    }
  },
};
</script>

<style>
</style>