<template></template>

<script>
export default {
  props: ["meta"],
  mounted() {
    this._meta();
  },
  watch: {
    meta: function () {
      this._meta();
    },
  },
  methods: {
    _meta() {
      let data = this.meta;
      if (typeof data === "undefined") {
        return;
      }
      if (typeof data.title === "undefined") {
        return;
      }
      if (data.title) {
        document.title = data.title;
      }
      if (data.canonical) {
        var link = document.createElement("link");
        link.rel = "canonical";
        link.href = data.canonical;
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      let metaArr = Object.values(data.meta);
      for (let i = 0; i < metaArr.length; i++) {
        let item = metaArr[i];
        let test = null;
        let test2 = null;
        
        if (item.name) {
          test = document.querySelector('meta[name="' + item.name + '"]');
        }
        if (item.property) {
          test2 = document.querySelector('meta[property="' + item.name + '"]');
        }
        if (test !== null || test2 !== null) {
          test.setAttribute("content", item.content);
          if (item.property) {
            test.setAttribute("property", item.property);
          }
        } else {
          var link = document.createElement("meta");
          if (item.property) {
            link.setAttribute("property", item.property);
          }
          if (item.name) {
            link.setAttribute("name", item.name);
          }
          link.content = item.content;
          document.getElementsByTagName("head")[0].appendChild(link);
        }
      }
    },
  },
};
</script>

<style>
</style>