<template>
  <Meta :meta="meta" />
  <Schema
    v-for="schema in subject.meta.schemas"
    :key="schema"
    :schema="schema"
  />
  <div class="row">
    <div class="col-sm-12">
      <div class="container">
        <main role="main" class="my-3">
          <div class="row">
            <div class="col-md-8 blog-main">
              <div class="blog-post border-top pt-3">
                <h1 class="blog-post-title">
                  Compare Top {{ compareNumber }} {{ subject.label }} For
                  {{ year }}
                </h1>
              </div>
              <div v-if="is_loading" class="text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <nav>
                <h2 class="h3">
                  The best {{ subject.label_singular }} to buy for {{ year }} are:
                </h2>
                <ul>
                  <li v-for="(product, index) in products" :key="product">
                    <strong>{{ product.name }}</strong> {{ product.price }}
                    <a
                      :href="product.url"
                      :title="'Buy now from ' + product.website.name"
                      target="_blank"
                      rel="nofollow sponsored"
                      >{{ product.website.name }} *</a
                    >
                  </li>
                  <li class="mt-3">
                    <a href="#popular-questions" class="text-dark">
                      <i class="fas fa-question-circle"></i>
                      Popular questions relating to
                      {{ subject.label }}</a
                    >
                    <ul>
                      <li v-for="question in questions" :key="question">
                        <a :href="'#question-' + question.uuid" class="text-dark">{{ question.question }}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div v-for="product in products" :key="product">
                <div v-for="schemaItem in product.schemas" :key="schemaItem">
                  <Schema :schema="schemaItem" />
                </div>
              </div>
              <div
                v-for="(product, index) in products"
                :key="product"
                class="row mt-3 border-top pt-3"
              >
                <div
                  class="col-md-3 col-sm-12"
                  v-lazy-container="{ selector: 'img' }"
                >
                  <img
                    :data-src="product.image"
                    class="img"
                    :alt="product.name + ' from ' + product.website.name"
                    :title="product.name + ' from ' + product.website.name"
                    dth="210"
                    height="210"
                  />
                </div>
                <div class="col-md-9 col-sm-12">
                  <h2>{{ index + 1 }}. {{ product.name }}</h2>
                  <p>{{ product.description }}</p>
                  <p>
                    <a
                      :href="product.url"
                      :title="'Buy now from ' + product.website.name"
                      target="_blank"
                      rel="nofollow sponsored"
                      >Buy Now</a
                    >
                    {{ product.price }}
                    {{ product.website.name }}
                  </p>
                  <table class="table table-border">
                    <thead>
                      <th colspan="3">Price comparison</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="comparison in products.compare"
                        :key="comparison"
                      >
                        <td>
                          {{ comparison.website.name }}
                        </td>
                        <td>
                          {{ comparison.price }}
                        </td>
                        <td>
                          <a
                            :href="product.url"
                            target="_blank"
                            :title="'Buy now from ' + comparison.website.name"
                            rel="nofollow sponsored"
                            >Buy Now</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row mt-3 border-top pt-3" id="popular-questions">
                <h4 class="h2">
                  <mark>Popular questions relating to {{ subject.label }}</mark>
                </h4>
                <div class="row">
                  <div
                    class="col-12"
                    v-for="question in questions"
                    :key="question"
                  >
                    <h2 class="h4" :id="'question-' + question.uuid">{{ question.question }}</h2>
                    <p>
                      Answer: <em>{{ question.answer }}</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <aside class="col-md-4 blog-sidebar">
              <div
                class="p-3 mb-3"
                v-lazy-container="{ selector: 'img' }"
                v-for="advert in adverts"
                :key="advert"
              >
                <a :href="advert.link" target="_blank" rel="nofollow sponsored"
                  ><img
                    border="0"
                    :width="advert.image_width"
                    :height="advert.image_height"
                    alt=""
                    :data-src="advert.image_src"
                /></a>
              </div>
            </aside>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Schema from "./../components/Schema.vue";
import Meta from "./../components/Meta.vue";
import moment from "moment";
export default {
  components: {
    Meta,
    Schema,
  },
  data() {
    return {
      subject: {
        meta: {
          schemas: [],
        },
      },
      meta: {},
      tableHeaders: [],
      products: [],
      questions: [],
      adverts: [],
      pickedProduct: null,
      year: "",
      imageHeight: "210px",
      imageWidth: "210px",
      compareNumber: 0,
    };
  },
  watch: {},
  mounted() {
    let templateObj = {
      name: "Product name",
      price: "£0.00",
      url: "/",
      description:
        "elit ut aliquam purus sit amet luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non enim praesent elementum facilisis leo vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus viverra vitae congue eu consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc sed velit dignissim sodales ut eu sem integer",
      image: this.defaultImage,
      website: {
        name: "Retailer Name",
      },
    };
    for (let i = 0; i <= 10; i++) {
      this.products.push(templateObj);
    }
    this.compareNumber = 10;
    if (this.$route.params.year === undefined) {
      this.year = moment().format("YYYY");
    } else {
      if (this.activeYears.includes(parseInt(this.$route.params.year))) {
        this.year = this.$route.params.year;
      } else {
        this.year = moment().format("YYYY");
      }
    }
    this.getSubject();
    this.getPopular();
  },
  methods: {
    getSubject() {
      this.axios
        .get(
          this.domain +
            "/api/subject?tags=" +
            this.$route.params.id +
            "&page=compare"
        )
        .then((response) => {
          this.subject = response.data.data.subject;
          this.meta = this.subject.meta;
          this.getPopular();
        });
    },
    getPopular() {
      this.is_loading = true;
      this.axios
        .get(
          this.domain +
            "/api/top10?tags=" +
            this.$route.params.id +
            "&more=yes&page=compare"
        )
        .then((response) => {
          this.tableHeaders = response.data.data.tableHeaders;
          this.products = response.data.data.products;
          this.adverts = response.data.data.adverts;
          this.questions = response.data.data.questions;
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
</style>