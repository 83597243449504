<template>
  <Meta :meta="meta" />
  <div class="row">
    <div class="col-sm-12">
      <div class="container">
        <main role="main" class="my-3">
          <div class="row">
            <div class="col-md-12">
              <div class="blog-post border-top pt-3">
                <h1 class="blog-post-title">
                  Browse the Best {{ subject.title }} Available
                </h1>
              </div>
              <div v-if="is_loading" class="text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <ComparisonTable
                :products="products"
                :tableHeaders="tableHeaders"
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import ComparisonTable from "./../components/Products/ComparisonTable.vue";
import Meta from "./../components/Meta.vue";
export default {
  components: {
    ComparisonTable, Meta,
  },
  data() {
    return {
      products: [],
      tableHeaders: [],
      is_loading: true,
      subject: {},
      meta: {},
    };
  },
  mounted() {
    this.getSubject();
  },
  methods: {
    getSubject() {
      this.axios
        .get(this.domain + "/api/subject?tags=" + this.$route.params.id + "&page=category")
        .then((response) => {
          this.subject = response.data.data.subject;
          this.meta = this.subject.meta;
          this.getPopular();
        });
    },
    getPopular() {
      this.is_loading = true;
      this.axios
        .get(
          this.domain + "/api/popular?tags=" + this.subject.key + "&more=yes"
        )
        .then((response) => {
          this.tableHeaders = response.data.data.tableHeaders;
          this.products = response.data.data.products;
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
</style>