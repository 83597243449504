<template>
  <div class="row higher-footer">
    <div class="col-sm-12">
      <div class="container">
        <div class="mt-4">
          <h3 class="text-white text-center my-3 h1">
            Our <mark v-if="baseSubject">{{ baseSubject }}'s</mark> recommended
            reading
          </h3>
        </div>

        <div v-if="is_loading" class="text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="jumbotron p-3 p-md-5 text-white rounded bg-dark my-3"
          v-if="!is_loading"
        >
          <div class="col-md-6 px-0">
            <h1 class="display-4 font-italic">{{ mainArticle.title }}</h1>
            <p class="lead my-3">
              {{ mainArticle.intro }}
            </p>
            <p class="lead mb-0">
              <router-link
                :to="{ name: 'Article', params: { id: mainArticle.slug } }"
                @click="reloadArticle(mainArticle.slug)"
                class="text-white font-weight-bold"
                >Continue reading...</router-link
              >
            </p>
          </div>
        </div>

        <div class="row mb-2">
          <div
            v-for="article in filterArticles"
            :key="article"
            class="col-md-6"
          >
            <div class="card flex-md-row mb-4 box-shadow h-md-250">
              <div class="card-body d-flex flex-column align-items-start">
                <strong class="d-inline-block mb-2 text-primary">{{
                  article.tag
                }}</strong>
                <h3 class="mb-0">
                  <router-link
                    :to="{ name: 'Article', params: { id: article.slug } }"
                    class="text-dark"
                    @click="reloadArticle(article.slug)"
                    >{{ article.title }}</router-link
                  >
                </h3>
                <div class="mb-1 text-muted">{{ article.displayDate }}</div>
                <p class="card-text mb-auto">{{ article.intro }}</p>
                <router-link
                  :to="{ name: 'Article', params: { id: article.slug } }"
                  @click="reloadArticle(article.slug)"
                  >Continue reading</router-link
                >
              </div>
              <div v-lazy-container="{ selector: 'img' }">
                <img
                  class="
                    card-img-right
                    flex-auto
                    d-none d-md-block
                    recommended-article-image
                  "
                  :data-src="article.image"
                  :alt="article.title"
                  :title="article.title"
                  width="160"
                  height="160"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["baseSubject", "currentArticle"],
  data() {
    return {
      articles: [],
      is_loading: true,
      articlesLoaded: false,
    };
  },
  mounted() {
    this.getArticles();
  },
  computed: {
    filterArticles() {
      if (this.articles.length == 0) {
        return {};
      }
      return this.articles.filter(function (el, key) {
        if (key == 0) {
          return false;
        }
        return true;
      });
    },
    mainArticle() {
      if (this.articles.length == 0) {
        return {};
      }
      let articles = this.articles.filter(function (el, key) {
        if (key == 0) {
          return true;
        }
        return false;
      });
      return articles[0];
    },
  },
  methods: {
    reloadArticle(slug) {
      if (slug !== this.currentArticle) {
        this.$emit("changeArticle", slug);
        this.getArticles();
      }
    },
    getArticles() {
      this.is_loading = true;
      this.axios
        .get(this.domain + "/api/articles?key=" + this.baseSubject)
        .then((response) => {
          this.articles = response.data.data.articles;
          this.articlesLoaded = true;
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
.recommended-article-image {
  max-width: 200px;
  height: 250px;
  max-height: 250px;
}
</style> 