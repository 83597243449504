<template>
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Compare All Products</h1>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" v-for="subject in subjects" :key="subject">
            <div class="card my-3">
              <img class="card-img-top" :src="subject.cover_image" :alt="subject.label + ' cover image'" />
              <div class="card-body">
                <h5 class="card-title">{{ subject.label }}</h5>
                <p class="card-text">
                  Compare {{ subject.label }} from various retailers.
                </p>
                <a  :href="
                  $router.resolve({
                    name: 'ProductCompareTopForCurrentYear',
                    params: { id: subject.slug, year: year, number: 10 },
                  }).href
                " class="btn btn-primary">Browse &amp; Compare</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      is_loading: true,
      subjects: [],
      year: "",
    };
  },
  mounted() {
    this.year = moment().format("YYYY");
    this.getSubjects();
  },
  methods: {
    getSubjects() {
      this.is_loading = true;
      this.axios
        .get(this.domain + "/api/all-subjects")
        .then((response) => {
          this.subjects = response.data.data.subjects;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
</style>