import {
    createApp,
    Vue
} from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import VueLazyload from '@jambonn/vue-lazyload'
import moment from "moment";

let domain = process.env.VUE_APP_API_DOMAIN;
let debug = false;
let defaultYear = 2020;
axios.defaults.headers.common['X-Compare-App'] = process.env.VUE_APP_APP;

let defaultImage =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANIAAADSBAMAAADNt4NxAAAAG1BMVEXMzMyWlpacnJyqqqrFxcWxsbGjo6O3t7e+vr6He3KoAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACf0lEQVR4nO3ay2/aQBAH4Fnj19EGAhztPFSO0DRSjwuJciY+5GxLSORoqMQZ0n+8M340kJjawlKkVL9PYhdrYMbrWcMBiAAAAAAAAAAAAAAAAAAAAAAAAAAA/idWcsNjJ+BhM9g1i5wn6fox0YzzOX44ahY5izPQ8yG5CedTI6fbKHIee0Ruz/R9zrde0FQ3iZzH8IgGdDvlfNOdpOS1kDuojLRjTyQfKc6XcFP4iJKdPaqOtOX08nwh54v5eB+sJ9WRtvajPB83XUm+zjDU1ZGWLD89zmf6Fyci7ZjJmN5do6fgVKSV+UDTu74n/VORVsJs+0q+ci+73d6JSCtOnlXylfen0U921ZFW7P7ffOVnznqyj6sjrRi+7xf3Z/k5Gmp7WB1pRXG+bp6v+G6wLrhTlREAAAAAAAAAAAAAAAAAAIAvSxWPr1fJ7/5oVsnjI/m1X/7NYnhyyO7DFdGjvyQVZH8++RfPvG1eKRsHO9oWlcwb/ZuHh0irF3KSmko0p+9XZGiHn1iXE1KRJ1Pn8kkqRVdkpxw5qDSMaVVU6izywZqoLdnr2krW8nHRSWf0i54f7kgts+n5QU5RccgN6O6wkjd2g6KSofPBDJSxe61pKl89Q5uBO4nSn3TNZ690Nl3TTCpxiFb84IZKj7JKWzstKqly8JQTr2oq8Y7ITjUYx4FkkzfK5JV98ui1kx6tydjQxzXRt6BuTfJSOfH4JeaFZOlkOliTEdFRJeuCPvaJnhb1laRPFKWbBW31vaST6a1P5AyPK2UH2Xiw96j25pN38N6jvZ5pcsKxvF6mt71HTlBRKb/C9355P9VXasBOW6doaPNZhdT4syoBfBV/AJYeagCrVor4AAAAAElFTkSuQmCC";

let years = [];
for (let i = defaultYear; i < moment().format("YYYY"); i++) {
    years.push(i);
}

let app = createApp(App)
app.config.globalProperties.axios = axios;
app.config.globalProperties.domain = domain;
app.config.globalProperties.debug = debug;
app.config.globalProperties.startYear = defaultYear;
app.config.globalProperties.activeYears = years;
app.config.globalProperties.compareDefault = 10;
app.config.globalProperties.defaultImage = defaultImage;
app.use(router).use(VueLazyload).mount('#app')