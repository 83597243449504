import {
  createRouter,
  createWebHistory
} from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Article from '../views/Article.vue';

import ProductCategory from '../views/ProductCategory.vue';
import ProductArticles from '../views/ProductArticles.vue';
import ProductCompare from '../views/ProductCompare.vue';
import ProductArticlesAll from '../views/ProductArticlesAll.vue';
import ProductCompareAll from '../views/ProductCompareAll.vue';
import ProductCompareArchiveAll from '../views/ProductCompareArchiveAll.vue';
import NotFound from '../views/NotFound.vue';

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: Article
  },
  {
    path: '/category/:id',
    name: 'ProductCategory',
    component: ProductCategory
  },
  {
    path: '/compare-products',
    name: 'ProductCompareAll',
    component: ProductCompareAll
  },
  {
    path: '/compare-products-archive',
    name: 'ProductCompareArchiveAll',
    component: ProductCompareArchiveAll
  },
  {
    path: '/best-of/:id',
    name: 'ProductBestOf',
    component: ProductCompare
  },
  {
    path: '/compare/:id',
    name: 'ProductCompare',
    component: ProductCompare
  },
  {
    path: '/compare/top-:number-:id',
    name: 'ProductCompareTop',
    component: ProductCompare
  },

  {
    path: '/compare/top-:number-:id-for-:year',
    name: 'ProductCompareTopForCurrentYear',
    component: ProductCompare
  },
  {
    path: '/compare/top-:number-:id-in-:year',
    name: 'ProductCompareTopForYear',
    component: ProductCompare
  },
  {
    path: '/articles/:id',
    name: 'ProductArticles',
    component: ProductArticles
  },
  {
    path: '/guides/:id', 
    name: 'ProductGuides',
    component: ProductArticles
  },
  {
    path: '/browse-articles',
    name: 'ProductArticlesAll',
    component: ProductArticlesAll
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
  }
})

export default router